/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { useTheme } from "@mui/material/styles";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  CssBaseline,
  Grid,
  Paper,
} from "@mui/material";
import { useSnackbar } from "notistack";
import DescriptionIcon from "@mui/icons-material/Description";
import ForumIcon from "@mui/icons-material/Forum";
import HomeIcon from "@mui/icons-material/Home";
import Header from "src/components/layout/mainLayout/header";
import NavigationList from "src/components/layout/mainLayout/navigationList";
import { useState, useEffect, type FC, type ReactNode } from "react";
import Main from "src/components/layout/mainLayout/main/Main";
import useNetwork from "src/hooks/useNetwork";
import { useLocation, useNavigate } from "react-router-dom";
import ProfileBottom from "src/components/layout/mainLayout/navigationList/profile";
import SettingsIcon from "@mui/icons-material/Settings";
import useStyles from "./Styles";

interface MainLayoutProps {
  children?: ReactNode;
}

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const theme = useTheme();
  const isOnline = useNetwork();
  const navigate = useNavigate();
  const location = useLocation();
  const locationValue = location.pathname;
  const [prevIsOnline, setPrevIsOnline] = useState(false);
  const [value, setValue] = useState(locationValue);
  const { enqueueSnackbar } = useSnackbar();
  const { classes } = useStyles();

  useEffect((): any => {
    if (!isOnline) {
      enqueueSnackbar("Internet connection lost. Please check your network.", {
        variant: "error",
      });
    } else if (isOnline && prevIsOnline) {
      enqueueSnackbar("Internet connection recovered.", {
        variant: "success",
      });
    }
  }, [isOnline, prevIsOnline, enqueueSnackbar]);

  useEffect(() => {
    if (!isOnline) setPrevIsOnline(true);
  }, [isOnline]);

  return (
    <Box className={classes.box}>
      <CssBaseline />
      <Grid className={classes.grid}>
        <NavigationList />
      </Grid>
      <Grid className={classes.gridg}>
        <Grid className={classes.gridHeader}>
          {" "}
          <Header />
        </Grid>
        <Grid className={classes.gridMain}>
          <Main theme={theme}>
            <Box className={classes.mainBox}>{children}</Box>
          </Main>
        </Grid>
        <Paper className={classes.paper} elevation={3}>
          <BottomNavigation
            className={classes.bottomNav}
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
              navigate(`${newValue}`);
            }}
          >
            <BottomNavigationAction
              className={classes.bottomNavDif}
              value="/"
              label="Dashboard"
              icon={<HomeIcon />}
            />
            <BottomNavigationAction
              className={classes.hide}
              value="/chats"
              label="Chats"
              icon={<ForumIcon />}
            />
            <BottomNavigationAction
              value="/reports"
              label="Reports"
              icon={<DescriptionIcon />}
            />
            <BottomNavigationAction
              className={classes.hide}
              value="/settings"
              label="Settings"
              icon={<SettingsIcon />}
            />
            <ProfileBottom />
          </BottomNavigation>
        </Paper>
      </Grid>
    </Box>
  );
};

export default MainLayout;
